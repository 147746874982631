import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { JobAPI } from 'api';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { Typography } from '@material-ui/core';

interface Props {
  control: Control;
  name: string;
  rules: RegisterOptions;
  errors: DeepMap<any, any>;
  label: string;
  className?: string;
}

/** Uses the selected Client ID */
const JobListAutocomplete = ({
  control,
  name,
  rules,
  errors,
  label,
  className,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { isFetching, data: jobs } = useQuery(['list-job-names'], () =>
    JobAPI.listAllJobSummaries()
  );

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={(props) => (
        <Autocomplete
          {...props}
          autoHighlight
          autoSelect
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          // setting the options as follows is important
          // otherwise it'll throw an error if a defaultValue is provided and data is null
          options={isFetching ? [] : jobs}
          loading={open && isFetching}
          noOptionsText="No matching Jobs"
          loadingText="Loading Jobs..."
          getOptionLabel={(option) =>
            `${option.siteAddress} ${option.refNumber ?? ''}`
          }
          renderOption={(option) => (
            <div>
              {option.siteAddress}
              {option.refNumber && (
                <Typography variant="caption" component="div">
                  Reference No: {option.refNumber}
                </Typography>
              )}
            </div>
          )}
          getOptionSelected={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              value=""
              variant="outlined"
              className={className}
              error={!!errors[name]}
              helperText={!!errors[name] && errors[name].message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {open && isFetching && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(_, data) => {
            return props.onChange(data);
          }}
        />
      )}
    />
  );
};

export default JobListAutocomplete;
